<template>
  <UiPopup
    :model-value="modelValue"
    :title="canClose ? 'Close lead' : 'Try to do more'"
    :description="
      canClose ? '' : 'To close lead you need to make at least 1 meaningful call or 4 attempts of call lead.'
    "
    :primary-button-text="canClose ? '' : 'Close lead'"
    :secondary-button-text="canClose ? '' : 'Keep working'"
    size="small"
    :loading="checking"
    @update:model-value="emits('update:modelValue', false)"
    @cancel="emits('cancel')"
    @confirm="canClose ? null : closeLead()"
  >
    <div class="transition-all duration-200">
      <UiLoader v-if="checking" />
      <template v-else-if="canClose">
        <p class="mb-8">If you want to close this lead, first indicate the resolution.</p>
        <div class="flex gap-4">
          <div class="flex flex-1 flex-col gap-4 rounded-xl border border-error-100 p-3">
            <p class="text-body-2">
              Lost lead - a lead that, due to various reasons, cannot finalize a deal. It will be archived within 2
              weeks.
            </p>
            <UiButtonBase id="btn_lost" class="mt-auto w-full" size="big" type="error" @click="closeAsLost()">
              LOST
            </UiButtonBase>
          </div>
          <div v-if="!authStore.getIsSdr" class="flex flex-1 flex-col gap-4 rounded-xl border border-success-100 p-3">
            <p class="text-body-2">
              Won lead - upon lead closure, you will create a deal for it, and then continue working with it on the
              Deals page.
            </p>
            <UiButtonBase id="btn_won" class="mt-auto w-full" size="big" type="success" @click="closeAsWon()">
              WON
            </UiButtonBase>
          </div>
        </div>
      </template>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import type { Lead } from '@/types'

import { useAuthStore } from '@/store/auth'

const authStore = useAuthStore()

const emits = defineEmits(['update:modelValue', 'lost', 'won', 'cancel'])

type Props = {
  modelValue: boolean
  lead: Lead
  leadWon?: boolean
  leadLost?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const checking = ref(true)
const canClose = ref(true)

onNuxtReady(async () => {
  checking.value = true
  if (props.leadWon) return closeAsWon()

  const result = await useCheckLeadsAction(props.lead.id, 'close')
  canClose.value = (result as any)?.data?.status
  if (canClose.value) {
    checkResolution()
  }
  checking.value = false
})

const closeAsLost = () => {
  emits('update:modelValue', false)
  emits('lost')
}

const closeAsWon = () => {
  emits('update:modelValue', false)
  emits('won')
}

const closeLead = () => {
  canClose.value = true
  checkResolution()
}

const checkResolution = () => {
  if (props.leadLost) {
    emits('update:modelValue', false)
    emits('lost')
  }
  if (props.leadWon) {
    emits('update:modelValue', false)
    emits('won')
  }
}
</script>

<style scoped></style>
